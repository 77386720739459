<!-- eslint-disable vue/attribute-hyphenation -->
<template lang="">
  <modal-padrao
    ref="modal-pontos"
    :max-width="$vuetify.breakpoint.name == 'xs' ? '90%' : '60%'"
    :titulo="$t('modulos.tipo_instrumento.botoes.novo_ponto')"
    :titulo-ok="`${$t('geral.botoes.salvar_sair')} (F2)`"
    :titulo-cancelar="`${$t('geral.botoes.fechar')} (ESC)`"
    :subtitulo="subtitulo"
    @ok="salvar_sair"
    @keydown="eventoUsabilidade"
  >
    <v-form
      ref="form"
      class="row"
    >
      <input-text
        ref="input-valor"
        v-model="form.valor"
        class="col-12 col-md-6"
        :label="$t('modulos.tipo_instrumento.formulario.pontos.valor')"
        :regras-personalizadas="regraValidacaoValor()"
        obrigatorio
        type="number"
      />
      <input-select
        v-model="form.tipoPonto"
        class="col-12 col-md-6"
        :label="$t('modulos.tipo_instrumento.formulario.pontos.tipo_ponto')"
        obrigatorio
        :options="opcoes.tipoPonto"
      />
      <input-textarea
        v-model="form.descricao"
        class="col-12 col-md-12"
        :label="$t('modulos.tipo_instrumento.formulario.pontos.descricao')"
        :max="200"
      />
    </v-form>
    <template v-slot:botaoAdicional>
      <botao-padrao
        color="primary"
        :class="{'ml-2': !EhTelaPequena}"
        :width="EhTelaPequena ? 240 : 'auto'"
        @click="salvar_continuar"
      >
        {{ `${$t('geral.botoes.salvar_continuar')} (F3)` }}
      </botao-padrao>
    </template>
  </modal-padrao>
</template>
<script>
import helpers from '@common/utils/helpers.js';
export default {
  data() {
    return {
      form: {},
      formReferencia: {},
      flagPontosDuplicados: null,
      subtitulo: '',
      opcoes: {
        tipoPonto: helpers.TipoPontoEnum,
      },
      edicao: false,
      faixaSelecionada: null
    };
  },
  computed: {
    EhTelaPequena(){
      return ['sm','xs'].includes(this.$vuetify.breakpoint.name)
    }
  },
  methods: {
    abrirModal(
      tipoInstrumentoNome,
      faixaSelecionada,
      pontoCalibracao,
      flagPontosDuplicados
    ) {
      this.flagPontosDuplicados = flagPontosDuplicados;
      this.subtitulo = `${tipoInstrumentoNome} - (${faixaSelecionada.descricao})`;
      this.faixaSelecionada = faixaSelecionada;
      this.iniciarForm();
      if (pontoCalibracao) {
        this.formReferencia = pontoCalibracao;
        this.edicao = true;
        this.form = { ...pontoCalibracao };
      }
      this.$refs['modal-pontos'].abrirModal();
    },
    iniciarForm() {
      this.form = {
        id: helpers.gerarNumeroAleatorio(),
        valor: null,
        descricao: null,
        tipoPonto: this.form.tipoPonto,
        origemPonto: null,
      };
      this.$refs.form?.resetValidation();
      this.edicao = false;
    },
    eventoUsabilidade(event){
      switch (event.key) {
        case 'F3':
            event.preventDefault()
            this.salvar_continuar()
          break;
        case 'F2':
            event.preventDefault()
            this.salvar_sair()
          break;
      }
    },
    salvar_sair(){
      if(!this.$refs.form?.validate()) return;
      this.salvar_continuar()
      this.$refs['modal-pontos'].fecharModal();
    },
    salvar_continuar() {
      if (!this.$refs.form.validate()) return;
      this.form.origemPonto = helpers.OrigemPontoEnum.find(
        (el) => el.text == 'Modelo'
      ).value;
      if (this.edicao) {
        Object.entries(this.form).forEach((value) => {
          this.formReferencia[value[0]] = value[1];
        });
        this.formReferencia.flagJaExistente = false;
      }
      if (!this.edicao) this.$emit('novoPonto', this.form);
      this.iniciarForm();
      this.$refs['input-valor']?.focus();
    },
    verificarExistente(valorPonto){
      return this.faixaSelecionada.modeloInstrumentoFaixaPonto
        .some(ponto => ponto.valor == valorPonto &&
           this.form.id != ponto.id && ponto.tipoPonto == this.form.tipoPonto)
    },
    regraValidacaoValor() {
      const regraValor = (valor) => {
        if(!this.form.tipoPonto) return true;

        if (this.flagPontosDuplicados == false) {
          if(this.verificarExistente(valor))
          return this.$t('modulos.tipo_instrumento.validacoes.ponto_existente')
        }

        if(this.form.tipoPonto === 'Percentual'){
          if (valor < 0 || valor > 100)
            return this.$t(
              'modulos.tipo_instrumento.validacoes.valor_percentual'
            );
          return true;
        }
        
        if (valor < this.faixaSelecionada.amplitudeMinima || valor > this.faixaSelecionada.amplitudeMaxima)
          return this.$t(
            'modulos.tipo_instrumento.validacoes.valor'
          );
        else return true;
      };
      return [regraValor];
    },
  },
};
</script>
